import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import gsap from "gsap";
import imagesLoaded from "imagesloaded";
import parallaxAnimation from "../utils/parallaxAnimation";

import { graphql } from "gatsby";
import { createLocaleTextGetter } from "../utils";

import "../styles/pages/restauration.scss";

import CoverImageDesk from "../images/pages/restauration/desktop/restauration_cover.jpg";
import AsideCoverImageDesk from "../images/pages/restauration/desktop/restauration_aside_cover.jpg";

import CoverImageMobile from "../images/pages/restauration/mobile/restauration_cover_mobile.jpg";
import AsideImageMobile from "../images/pages/restauration/mobile/restauration_cover_mobile-1.jpg";

import BastideImageDesk from "../images/pages/restauration/desktop/bastide_image.jpg";
import BocuseImageDesk from "../images/pages/restauration/desktop/bocuse_image.jpg";
import VoutesImageDesk from "../images/pages/restauration/desktop/voutes_image.jpg";
import eventBus from "../utils/eventBus";
import getInnerHeight from "../utils/getInnerHeight";

const RestaurantCardsWrapper = (props) => {
  const [isDrag, setIsDrag] = React.useState(false);
  let isAnimated = false;
  const [dragStartPosition, setDragStartPosition] = React.useState(0);
  const [currentCard, setCurrentCard] = React.useState(0);

  
  const getTranslateXValue = (element) => {
    // Get the x value of thanslate property on DOM Element
    const translateX = window
      .getComputedStyle(element)
      .getPropertyValue("transform");
    
    // Get the value of translateX property
    const translateXValue = translateX.split(",")[4];
    // Get the value of translateX property
    // Return the value of translateX property
    // return value in integer
    return parseInt(translateXValue, 10);
  };

  const switcher = () => {
    if (window.innerWidth > 769) {
      desktopInit();
    } else {
      mobileInit();
    }
    document.addEventListener("resize", () => {
      if (window.innerWidth > 769) {
        desktopInit();
      } else {
        mobileInit();
      }
    });
  };
  const desktopInit = () => {
    let cards = document.querySelectorAll(".bi_restauration_restaurants_card");
    let lastCard = cards[cards.length - 1];
    let cardsLength = cards.length;
    let cardsWidth = cards[0].offsetWidth;
    // Setting hover events to all cards except the last one
    for (let i = 0; i < cardsLength - 1; i++) {
      // On Mouse hover move all next cards to the left
      let nextCards = Array.from(cards).slice(i + 1);
      cards[i].addEventListener("click", () => {
        // select last cards after current card
        document.querySelector("main").style.cursor = "pointer";
        gsap.killTweensOf(cards[i]);
        gsap.to(cards[i], { x: 0, duration: 0.5, ease: "linear" });
        nextCards.forEach((el, index) => {
          gsap.killTweensOf(el);
          if (
            getTranslateXValue(nextCards[0]) > -1 ||
            !getTranslateXValue(nextCards[0])
          ) {
            gsap.to(el, {
              x: -(cardsWidth / 2 + 80),
              duration: 0.5,
              ease: "linear",
            });
          } else {
            if (getTranslateXValue(cards[i]) > -1) {
              gsap.to(el, { x: 0, duration: 0.5, ease: "linear" });
            } else {
              gsap.to(el, {
                x: -(cardsWidth / 2 + 80),
                duration: 0.5,
                ease: "linear",
              });
            }
          }
        });
      });
    }
    lastCard.addEventListener("click", () => {
      gsap.killTweensOf(cards);
      gsap.to(cards, { x: 0, duration: 0.5, ease: "linear" });
    });
  };

  const mobileInit = () => {
    initSliderFil();
    initDraggable();
  };

  const initSliderFil = () => {
    setTimeout(() => {
      // Get all cards in the DOM after that get the card with the bigger height and set this height to the parent elemnt
      let cards = document.querySelectorAll(
        ".bi_restauration_restaurants_card"
      );
      let parentCard = document.querySelector(
        ".bi_restauration_restaurants_wrapper"
      );

      let arraysHeight = [];
      cards.forEach((card, index) => {
        // push p length to arraysHeight
        arraysHeight.push(card.querySelector("p").innerHTML.length);
      });
      let maxHeight = Math.max(...arraysHeight);
      gsap.set(parentCard, {
        height: cards[arraysHeight.indexOf(maxHeight)].offsetHeight + 20,
      });
      gsap.set(cards, {height: getInnerHeight(cards[arraysHeight.indexOf(maxHeight)]) });
    }, 500);
    window.addEventListener("resize", () => {
      let cards = document.querySelectorAll(
        ".bi_restauration_restaurants_card"
      );
      let parentCard = document.querySelector(
        ".bi_restauration_restaurants_wrapper"
      );

      let arraysHeight = [];
      cards.forEach((card, index) => {
        // push p length to arraysHeight
        arraysHeight.push(card.querySelector("p").innerHTML.length);
      });
      let maxHeight = Math.max(...arraysHeight);
      gsap.set(parentCard, {
        height: cards[arraysHeight.indexOf(maxHeight)].offsetHeight + 20,
      });
    });
  };

  const initDraggable = () => {
    let cards = document.querySelectorAll(".bi_restauration_restaurants_card");
    cards.forEach((card) => {
      card.addEventListener("mousedown", (e) => {
        onDragStart(e, card);
      });
      card.addEventListener("touchstart", (e) => {
        onDragStart(e, card);
      });

      card.addEventListener("mousemove", (e) => {
        onMove(e, card);
      });
      card.addEventListener("touchmove", (e) => {
        onMove(e, card);
      });

      card.addEventListener("mouseleave", (e) => {
        if (!isAnimated) {
          onDragEnd(e, card);
        }
      });
      card.addEventListener("mouseup", (e) => {
        if (!isAnimated) {
          onDragEnd(e, card);
        }
      });
      card.addEventListener("touchend", (e) => {
        if (!isAnimated) {
          onDragEnd(e, card);
        }
      });
    });
  };

  const nextCard = (el) => {
    setCard("next", el);
  };

  const prevCard = (el) => {
    setCard("prev", el);
  };

  const setCard = (sens, card) => {
    let cards = document.querySelectorAll(".bi_restauration_restaurants_card");
    const cardLeave = (value) => {
      gsap.killTweensOf(card);
      let tl = gsap.timeline({
        onComplete: () => {
          setTimeout(() => {
            tl.kill();
          }, 100);
        },
      });
      if (sens === "next") {
        tl.to(card, {
          x: "150%",
        });
        tl.set(card, {
          x: 0,
          zIndex: 0,
          onComplete: () => {
            
            if (isAnimated) {
              setCurrentCard(value);
              isAnimated = false;
            }
          },
        });
      } else {
        tl.to(card, {
          x: "-150%",
        });
        tl.set(card, {
          x: 0,
          zIndex: 0,
          onComplete: () => {
            
            if (isAnimated) {
              setCurrentCard(value);
              isAnimated = false;
            }
          },
        });
      }
    };
    /*if (sens === "next") {*/
      if (currentCard < cards.length - 1) {
        cardLeave(currentCard + 1);
      } else {
        cardLeave(0);
      }
    /*} else {
      if (currentCard > 0) {
        cardLeave(currentCard - 1);
      } else if (currentCard === 0) {
        cardLeave(cards.length - 1);
      }
    }*/
  };

  const onMove = (e, el) => {
    if (e.touches) {
      e.preventDefault();
      e = e.touches[0];
    }
    if (isDrag && !isAnimated) {
      // Set the element with the mouse position relative to the start position on the element even if the element is already translated
      let x = e.clientX - dragStartPosition;
      gsap.set(el, { x: x });
    }
  };

  const onDragStart = (e, el) => {
    if (e.touches) {
      e = e.touches[0];
    }
    setIsDrag(true);
    setDragStartPosition(e.clientX);
  };

  const onDragEnd = (e, el) => {
    if (e.touches) {
      e = e.touches[0];
    }
    setIsDrag(false);

    // get difference with translate X value from element and 0
    let diff = getTranslateXValue(el);
    if (diff > 0 && !isAnimated) {
      isAnimated = true;
      
      // next card
      nextCard(el);
    } else if (diff < 0 && !isAnimated) {
      isAnimated = true;
     
      // prev card
      prevCard(el);
    }
  };

  const setZIndex = () => {
    let restaurant_card = Array.from(
      document.querySelectorAll(".restaurant_card")
    ).reverse();
    let current = restaurant_card[currentCard];
    let nextCard =
      restaurant_card[
        currentCard < restaurant_card.length - 1 ? currentCard + 1 : 0
      ];
    let lastCard =
      restaurant_card[
        currentCard > 0 ? currentCard - 1 : restaurant_card.length - 1
      ];

    gsap.set(current, { zIndex: 2 });
    gsap.set(nextCard, { zIndex: 1 });
    gsap.set(lastCard, { zIndex: 0 });
  };

  useEffect(() => {
    switcher();
  });

  useEffect(() => {
    
    setZIndex();
  }, [currentCard]);

  useEffect(() => {
   
  }, [isAnimated]);

  return (
    <div className="bi_restauration_restaurants_wrapper">
      <div className="bi_restauration_restaurants_card restaurant_card">
        <h2>
          <span>{props.cards[2].title}</span>
          <a href={props.cards[2].link} target="_blank">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.5" cy="16.5" r="16" stroke="#D7D7D7" />
              <path
                d="M13.1064 22.185L20.0117 15.2797L20.0117 21.6233L21.6175 21.6176V12.5391H12.539L12.539 14.1392L18.8769 14.1449L11.9716 21.0502L13.1064 22.185Z"
                fill="black"
              />
            </svg>
          </a>
        </h2>
        <p>
        {props.localeTextGetter(props.cards[2].description)}
        </p>
        <picture>
          <img src={BastideImageDesk} />
        </picture>
      </div>
      <div className="bi_restauration_restaurants_card restaurant_card">
        <h2>
          <span>{props.cards[1].title}</span>
          <a href={props.cards[1].link} target="_blank">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.5" cy="16.5" r="16" stroke="#D7D7D7" />
              <path
                d="M13.1064 22.185L20.0117 15.2797L20.0117 21.6233L21.6175 21.6176V12.5391H12.539L12.539 14.1392L18.8769 14.1449L11.9716 21.0502L13.1064 22.185Z"
                fill="black"
              />
            </svg>
          </a>
        </h2>
        <p>
        {props.localeTextGetter(props.cards[1].description)}
        </p>
        <picture>
          <img src={VoutesImageDesk} />
        </picture>
      </div>
      <div className="bi_restauration_restaurants_card restaurant_card">
        <h2>
          <span>{props.cards[0].title}</span>
          <a href={props.cards[0].link} target="_blank">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.5" cy="16.5" r="16" stroke="#D7D7D7" />
              <path
                d="M13.1064 22.185L20.0117 15.2797L20.0117 21.6233L21.6175 21.6176V12.5391H12.539L12.539 14.1392L18.8769 14.1449L11.9716 21.0502L13.1064 22.185Z"
                fill="black"
              />
            </svg>
          </a>
        </h2>
        <p>
          {
            props.localeTextGetter(props.cards[0].description)
          }
        </p>
        <picture>
          <img src={BocuseImageDesk} />
        </picture>
      </div>
      <div className="bi_restauration_restaurants_fil">
        <span className={currentCard === 0 ? "active" : ""}></span>
        <span className={currentCard === 1 ? "active" : ""}></span>
        <span className={currentCard === 2 ? "active" : ""}></span>
      </div>
    </div>
  );
};

const RestaurationPage = ({ data, pageContext }) => {
  const localeTextGetter = createLocaleTextGetter(pageContext.locale);
 
  let splittedData = [];
  React.useEffect(() => {
    const textAnimation = require("../utils/textAnimation.js").default;
    if (window.innerWidth > 769) {
      let tl = gsap.timeline({});
      tl.set("main", { autoAlpha: 0 });
      imagesLoaded(".bi_restauration_header", () => {
        eventBus.dispatch("page-loaded");
        splittedData = textAnimation.splitAllText();
        
        tl.set("main", { autoAlpha: 1 });
        /* tl.pause();
        setTimeout(() => {
          tl.play();
        }, 500); */
        textAnimation.splitTitleApparition(
          splittedData.splittedTitles.find((el) => el.id === "h1").chars,
          tl
        );
        textAnimation.splitBlockApparition(
          splittedData.splittedBlocks.find((el) => el.id === "header").lines
            .lines,
          "-=0.5",
          "word",
          tl
        );
        tl.fromTo(
          ".bi_restauration_header picture",
          { autoAlpha: 0, y: 100 },
          {
            autoAlpha: 1,
            y: 0,
            duration: 1,
            ease: "power2.inOut",
            stagger: 0.2,
          },
          "-=1"
        );
        parallaxAnimation.imagesParallax();
        parallaxAnimation.blockParallax();
      });
    }
  }, []);
  return (
    <main className="bi_restauration">
      <Helmet htmlAttributes={{ lang: pageContext.locale }}>
        <title>
          {pageContext.locale === "fr"
            ? "Restauration | Les Suites de l'île Barbe"
            : "Restauration | The Suites of Île Barbe"}
        </title>
        <meta
          type="description"
          content={
            pageContext.locale === "fr"
              ? "Voici une sélection de nos trésors gastronomiques."
              : "Here is a selection of our gastronomic treasures."
          }
        />
      </Helmet>
      <div className="bi_restauration_header bi_pageA_header">
        <h1 data-splitted-title data-s-tag="h1">
          {pageContext.locale === "fr" ? "Restauration" : "Catering"}
        </h1>
        <p data-splitted-block data-s-tag="header">
          {pageContext.locale === "fr"
            ? "Les environs de l’Île barbe regorgent de trésors gastronomiques. Voici une sélection de nos tables préférées :"
            : "The area around l’Île barbe is full of gastronomic treasures. Here is a selection of our favorite restaurants:"}
        </p>
        <picture
          data-parallax="true"
          data-p-direction="bottom"
          data-p-vitesse="0.5"
        >
          <source srcSet="" media="(min-width: 768px)" />
          <source srcSet={CoverImageMobile} media="(max-width: 767px)" />
          <img src={CoverImageDesk} alt="Restauration" />
        </picture>
      </div>
      <section className="bi_restauration_restaurants">
        <RestaurantCardsWrapper
          cards={data.sanityRestaurationDocumentType.FeaturedArray}
          localeTextGetter={localeTextGetter}
        />
      </section>
      <section className="bi_restauration_aside">
        <h2>
          {pageContext.locale === "fr"
            ? "À côté des Suites"
            : "Next to the Suites"}
        </h2>
        <div className="bi_restauration_aside_wrapper restauration_grid">
          {data.sanityRestaurationDocumentType.NextArray.map((el, i) => (
            <div key={i} className="bi_restauration_aside_wrapper_element restauration_grid_element">
              <h3>
                <span>{el.title}</span>{" "}
                <a href={el.link} target="_blank">
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16.5" cy="16.5" r="16" stroke="#D7D7D7" />
                    <path
                      d="M13.1064 22.185L20.0117 15.2797V21.6233L21.6175 21.6176V12.5391H12.539V14.1392L18.8769 14.1449L11.9716 21.0502L13.1064 22.185Z"
                      fill="black"
                    />
                  </svg>
                </a>
              </h3>
              <p>{localeTextGetter(el.description)}</p>
            </div>
          ))}
        </div>
        <picture
          data-parallax="true"
          data-p-direction="bottom"
          data-p-vitesse="0.5"
        >
          <source srcSet={AsideImageMobile} media="(max-width: 767px)" />
          <img src={AsideCoverImageDesk} />
        </picture>
      </section>
      <section className="bi_restauration_incity">
        <h2>
          {pageContext.locale === "fr"
            ? "En centre-ville"
            : "In the town center"}
        </h2>
        <div className="bi_restauration_aside_wrapper restauration_grid">
          {data.sanityRestaurationDocumentType.CenterTownArray.map((el, i) => (
            <div key={i} className="bi_restauration_aside_wrapper_element restauration_grid_element">
              <h3>
                <span>{el.title}</span>{" "}
                <a href={el.link} target="_blank">
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16.5" cy="16.5" r="16" stroke="#D7D7D7" />
                    <path
                      d="M13.1064 22.185L20.0117 15.2797V21.6233L21.6175 21.6176V12.5391H12.539V14.1392L18.8769 14.1449L11.9716 21.0502L13.1064 22.185Z"
                      fill="black"
                    />
                  </svg>
                </a>
              </h3>
              <p>{localeTextGetter(el.description)}</p>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default RestaurationPage;

export const pageQuery = graphql`
  query ResturantQuery {
    sanityRestaurationDocumentType {
      CenterTownArray {
        title
        link
        description {
          _key
          _type
          fr
          en
        }
      }
      NextArray {
        title
        link
        description {
          _key
          _type
          fr
          en
        }
      }
      FeaturedArray {
        title
        link
        alt {
          _key
          _type
          fr
          en
        }
        description {
          _key
          _type
          fr
          en
        }
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
